.emptySpace {
    height: 95vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
}

@media (max-width: 575.98px) { 
    
    .emptySpace {

        h2, small {
            display: none;
        }
        
    }
}