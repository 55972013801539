.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    min-height: 680px;

    max-width: 1080px;
    margin: auto;
    padding-top: 12rem;

    font-size: 1.1rem;

    background-color: #f6fdff;

    position: relative;
    z-index: 1;

    h1 {
        font-family: 'Acme', sans-serif;
        font-size: 3.8rem;
    }

    p {
        text-align: center;
        width: 80%;

        .highlight {
            font-size: 1.4rem;
            color: #892CA0;
            font-weight: 500;
        }
    }
}