/* You can add global styles to this file, and also import other style files */

$button-background : #18082B;
$button-border : #18082B;

// Hover colors for Primary Btn
$button-background-hover : #2f0b5a;
$button-border-hover : #2f0b5a;

$button-color : #FFF;
$button-transparent-color: #18082B;


.button {
	display: inline-block;
	position: relative;
	cursor: pointer;
	outline: none;
    white-space: nowrap;
    
	margin-top: 10px;
    
    font-size: 14px;
    
    background-color: transparent;
    border: none;

	font-weight: 400;
    letter-spacing: 1px;
    
    text-decoration: none;

    &.primaryBtn {
        text-transform: capitalize;

        background-color: $button-color;
        border: .05rem solid $button-border;
        color: $button-background;
    }

    &.primaryBtn:hover {
        background-color: $button-background-hover;
        border: .05rem solid $button-border-hover;
        color: $button-color;

        text-decoration: none;
    }

    &.buttonRounded { 
        border-radius: 5px;
    } 

    &.bigButton {
        text-transform: uppercase;
        width: 300px;
        padding: 1.5rem;

        font-size: 1.4rem;
        font-weight: 100;
    }
}


body, html {
    height: 100%;
}


body {
    padding: 0px;
    margin: 0px;

    background: #f6fdff;

    font-family: 'Poppins', sans-serif;
}


#intro-particles-js {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0rem;
    left: 0rem;
}


.shake-horizontal {
	-webkit-animation: shake-horizontal 8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 5s infinite ;
	animation: shake-horizontal 8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 5s infinite ;
}


/** *********************** Animation **************************/

/* ----------------------------------------------
 * Generated by Animista on 2019-7-26 12:9:17
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
    0%, 20%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    2%, 6%, 10%, 14% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    4%, 8%, 12% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    16% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    18% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
}

@keyframes shake-horizontal {
    0%, 20%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    2%, 6%, 10%, 14% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    4%, 8%, 12% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    16% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    18% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
}


.projectDetailsArea {
    padding-top: 110px;

    .projectInfo {
        padding: 1.5rem 1rem;
        background-color: #d1ecf1;
        border: .01rem solid #bee5eb;
        margin: 1rem auto;
        margin-bottom: 0rem;

        p {
            margin-bottom: 0.2rem;
        }

        .label {
            font-size: .9rem;
            margin-right: .25rem;
            text-transform: capitalize;
        }

        .value {
            font-size: 1.1rem;
        }

        .smallText {
            font-size: .9rem;
        }
    }
}

.projectDetailsArea {

    .projectInfo {

        .tooltip {

            .tooltip-inner {

                min-width: 300px;
            }
        }
    }
}

.blockquote {

    width: 90%;

    border-left: .4rem solid #cdcdcd;
    
    padding-left: .5rem;
    margin-top: .5rem;
    
    color: #222;
}


// Footer Styling
.issuesInfo {
    margin-top: 2rem;

    p {
        padding-right: 3rem;
        font-size: .8rem;
    }
}


.landingFooter {
    padding: 3rem;

    border-top: .01rem solid #90268e;
    
    color: #444;
    background-color: #e4c8e233;

    font-size: .9rem;

    .minus1by12Info {
        background-color: #80757f80;

        padding: 2rem 1rem;
        margin-top: 2rem;
        
        border-radius: 5px;
    }

    .widget {
        display: flex;
        align-items: flex-start;

        margin-top: 2rem;

        small {
            font-size: .8rem;
        }

        .footer-logo {
            margin: 0rem 1.5rem 0rem 0rem;
            width: 80px;
        }

        .info {
            width: 800px;

            a {
                color: #754872;
            }
        }
    }
}

@media (max-width: 575.98px) { 
    
    .landingFooter {

        .footer-logo {
            display: none;
        }

        .minus1by12Info {
            padding: 1rem 0rem;
        }
        
    }
}


#copyrights {
    padding: 2rem 0.5rem;

    color: #999;
    background-color: #e4c8e233;
    border-top: .01rem solid #ccc;

    width: 100%;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

    font-size: 14px;
    
    line-height: 1.8;
    text-align: center;
}