.header {
    padding: 1rem;

    display: flex;
    align-items: center;

    position: relative;
    z-index: 5;

    background-color: #f6fdff;
    border-bottom: 0.1rem solid #892CA0;

    position: fixed;

    width: 100%;

    .logo {
        width: 60px;
        height: 60px;
    }

    .companyText {

       display: flex;
       align-items: baseline;

       margin-left: 1rem;
       margin-bottom: .5rem;
       
       border-bottom: .25rem solid #777;
       padding-bottom: .1rem;
       
       border-radius: 2px;

       color: #892CA0; // 9816d8; 

        h2 {
           margin-bottom: 0rem;
           margin-left: .25rem;

           line-height: 36px;
       }
    }

    .inspiringMsg {
        margin: .4rem 0rem 0rem 1rem;
        font-size: .8rem;
        font-style: italic;

        p {
            margin: 0rem;
            line-height: 1.4rem;
        }

        .biggerText {
            text-transform: capitalize;
            font-size: 1.1rem;
        }

        &.rightIndent {
            text-indent: 2rem;
            position: relative;
            top: -4px;
        }
    }

}


@media (max-width: 575.98px) { 

    .header {

        .inspiringMsg {
    
            &.rightIndent {
                text-indent: 0rem;
            }

            .biggerText {
                font-size: 1rem;
            }
        }
    }
}