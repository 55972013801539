.projectCard {

    overflow: hidden;
    margin-bottom: 4rem;

    -webkit-box-shadow: 0px 4px 10px 0px #666;
    -moz-box-shadow: 0px 4px 10px 0px #666;
    box-shadow: 0px 4px 10px 0px #666;

    .card-header {
        h3 {
            margin-bottom: 0rem;
        }
    }

    .card-text {
        font-size: .9rem;
    }

    h1, h6, h3, h2, h4, h5 {
        margin-bottom: .25rem;
    }

    .attributes, .timeline, .commitment {
        margin-bottom: 1rem;
    }

    .attributes {

        p {
            margin-bottom: 0.2rem;
            text-transform: capitalize;
        }

        .label {
            font-size: .8rem;
            margin-right: .25rem;
        }
    }

    .timeline {
        margin-top: 1rem;
    }

    .card-footer {

        // text-align: right;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        
        // &.justifyBetween {
        //     justify-content: space-between;
        // }

        // &.justifyEnd {
        //     justify-content: flex-end;
        // }

        p {
            margin-bottom: 0.2rem;
        }

        .label {
            font-size: .8rem;
            margin-right: .25rem;
        }

        .value {
            font-weight: 900;
            text-transform: capitalize;
        }
    }
}